import { Toggle } from '@cfra-nextgen-frontend/shared/src/components/Toggle/ToggleVariant1';
import { useUserEntitlements } from '@cfra-nextgen-frontend/shared/src/hooks/useUserEntitlements';
import { LookupItems, ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { useMemo } from 'react';
import { difference } from 'lodash';

interface BiggestConcernToggleProps {
    onRegionChange: (regions: string[]) => void;
}

enum ToggleTypes {
    All = 'All',
    NorthAmerica = 'North America',
    Europe = 'Europe'
}

export function BiggestConcernToggle({ onRegionChange }: BiggestConcernToggleProps) {
    const { userEntitlements } = useUserEntitlements();

    const regionMapping: Record<ToggleTypes, string[]> = useMemo(() => ({
        [ToggleTypes.All]: ['2', '3'],
        [ToggleTypes.NorthAmerica]: ['2'],
        [ToggleTypes.Europe]: ['3'],
    }), []);

    const entitlmentMappings = useMemo(() => {
        let regionEntitlements: string[] = [];

        userEntitlements?.packages.forEach((packg) => {
            const companyReportEntitled = packg.entitlements?.some(ent => 
                ent.item_lid === LookupItems.ResearchTypeId && Number(ent.value) === ResearchTypeId.FrsCompanyReport);
            
            if (companyReportEntitled)
                regionEntitlements = regionEntitlements.concat(packg.entitlements?.filter(ent => 
                    ent.item_lid === LookupItems.RegionLid
                ).map(ent => ent.value.toString()) || []);
        });

        return {
            [ToggleTypes.All]: difference(regionMapping[ToggleTypes.All], regionEntitlements).length === 0,
            [ToggleTypes.NorthAmerica]: difference(regionMapping[ToggleTypes.NorthAmerica], regionEntitlements).length === 0,
            [ToggleTypes.Europe]: difference(regionMapping[ToggleTypes.Europe], regionEntitlements).length === 0
        }
    }, [regionMapping, userEntitlements]);

    const options = useMemo(() => {
        return [{ 
            value: ToggleTypes.All, disabled: !entitlmentMappings[ToggleTypes.All]
        }, { 
            value: ToggleTypes.NorthAmerica, disabled: !entitlmentMappings[ToggleTypes.NorthAmerica]
        }, { 
            value: ToggleTypes.Europe, disabled: !entitlmentMappings[ToggleTypes.Europe]
        }];
    }, [entitlmentMappings]);

    const getDefaultSelectionIndex = (entitlmentMappings: Record<string, boolean>) => {
        return entitlmentMappings[ToggleTypes.All] ? 0 : (entitlmentMappings[ToggleTypes.NorthAmerica] ? 1 : (entitlmentMappings[ToggleTypes.Europe] ? 2 : 0))
    }

    const handleToggle = (index: number) => {
        const selectedRegion = options[index];
        onRegionChange(regionMapping[selectedRegion.value]);
    };

    return <Toggle options={options} onChange={handleToggle} defaultSelectionIndex={getDefaultSelectionIndex(entitlmentMappings)} />;
}
