import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridThemes, keepNoLeftPaddingOnMove } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { StyledAvatar } from '@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar';
import { BannerCard } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { CommonButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/CommonButton';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import {
    TypographyStyle2,
    TypographyStyle5,
    TypographyStyle6
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { useUserEntitlements } from '@cfra-nextgen-frontend/shared/src/hooks/useUserEntitlements';
import { ApiNames, fontFamilies, fontWeights, LookupItems, RequestTypes, ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, createTheme, Grid, Stack, SxProps, Theme, useMediaQuery } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import BiggestConcernsBanner from 'assets/images/biggest_concerns_banner.svg';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { BadgeBiggestConcerns } from 'components/BadgeBiggestConcerns';
import moment from 'moment';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { defaultAutosizePadding, defaultTooltipShowDelay } from 'utils/lookAndFeel';
import checkRounded from '@cfra-nextgen-frontend/shared/src/assets/icons/check-rounded.svg';
import { RoundedTextButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/RoundedTextButton';
import { roundedTextButtonThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { TableSkeleton } from '@cfra-nextgen-frontend/shared/src/utils/skeleton';

const trendingHeaderStyles = {
    height: '60px',
    padding: '20px',
    alignItems: 'center',
};

const MAX_ROWS_DISPLAY_COUNT = 11;

export const BiggestConcernTitle = ({ titleStyles }: { titleStyles?: SxProps<Theme> }) => {
    return (
        <Stack direction='row' gap='8px' alignItems='center' height='50px'>
            <TypographyStyle2 fontWeight={fontWeights.Medium}>Biggest Concerns</TypographyStyle2>
            <BadgeBiggestConcerns />
        </Stack>
    );
};

const gridTheme = [AgGridThemes.BcGridTheme, 'ag-panel-top-position-fixed'];

export function BiggestConcernsCard() {
    const navigate = useNavigate();
    const { userEntitlements } = useUserEntitlements();
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const gridRef = useRef<AgGridReact>(null);
    const betweenMdAndLg = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));

    
    let regionEntitlements: string[] = [];

    userEntitlements?.packages.forEach((packg) => {
        const companyReportEntitled = packg.entitlements?.some(ent => 
            ent.item_lid === LookupItems.ResearchTypeId && Number(ent.value) === ResearchTypeId.FrsCompanyReport);
        
        if (companyReportEntitled)
            regionEntitlements = regionEntitlements.concat(packg.entitlements?.filter(ent => 
                ent.item_lid === LookupItems.RegionLid && ['2', '3'].includes(ent.value.toString())
            ).map(ent => ent.value.toString()) || []);
    });

    const bcScreenerQuery = sendSingleRequest?.(
        {
            path: 'company/screener',
            securityType: 'company',
            view: 'biggest_concerns',
            orderBy: 'frs_biggest_concerns_list.addition_date',
            sortDirection: 'desc',
            requestBody: {
                filters: {
                    values: {
                        'frs_biggest_concerns_list.is_biggest_concern': {
                            values: [true],
                        },
                    },
                },
            },
            config: {
                enabled: regionEntitlements.length > 0
            },
        },
        {
            requestType: RequestTypes.POST,
            path: 'company/screener',
            queryKeyFirstElement: 'trendingResearchScreenerQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchCompanyData>;

    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (!bcScreenerQuery?.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }
        const result = extractFromScreenerData({
            screenerData: bcScreenerQuery?.data,
            cardName: 'biggest concern',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
            keepNoLeftPadding: true,
        });
        return result;
    }, [bcScreenerQuery?.data]);

    const bcCompanyList = useMemo(() => {
        const data: any[] = [];
        if (!bcScreenerQuery?.isLoading && bcScreenerQuery?.data?.results?.company) {
            const threeMonthsAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');
            let bcCompanyList = bcScreenerQuery?.data?.results?.company || [];

            const threeMonthsBcCompanyList = bcCompanyList.filter((bc) => {
                const addition_date = bc?.frs_biggest_concerns_list?.addition_date;
                if (addition_date) {
                    return moment(addition_date).isAfter(threeMonthsAgo);
                }
                return false;
            });

            if (threeMonthsBcCompanyList.length >= 10) {
                return threeMonthsBcCompanyList;
            }

            return bcCompanyList.slice(0, 10);
        }
        return data;
    }, [bcScreenerQuery?.isLoading, bcScreenerQuery?.data?.results?.company]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const handleOnViewAllClick = useCallback(
        (url: string) => {
            navigate(url);
        },
        [navigate],
    );

    return (
        <BannerCard
            title={<BiggestConcernTitle />}
            bannerImage={BiggestConcernsBanner}
            subHeader={
                <Stack direction='row' gap='8px' alignItems='center' justifyContent='space-between'>
                    <Box sx={trendingHeaderStyles}>
                        <TypographyStyle2 fontWeight={fontWeights.Medium} lineHeight='18px'>
                            Latest Additions
                        </TypographyStyle2>
                    </Box>
                    <CommonButton
                        theme={createTheme({})}
                        disabled={regionEntitlements.length === 0}
                        content={
                            <TypographyStyle5 sx={{ textTransform: 'none', lineHeight: '18px', color: regionEntitlements.length === 0 ? '#00000042' : '#007AB9' }}>
                                View All
                            </TypographyStyle5>
                        }
                        onClickCallback={() => handleOnViewAllClick('/accounting-lens/biggest-concern')}
                        outlined={true}
                        sx={{ padding: '6px 14px', justifyContent: 'space-between', marginRight: '20px', '&.Mui-disabled': { background: '#e4e8f4' } }}
                    />
                </Stack>
            }
            isLoading={bcScreenerQuery?.isLoading}
            noResults={regionEntitlements.length > 0 && (bcCompanyList?.length || 0) === 0}
            containerStyles={
                bcCompanyList.length > MAX_ROWS_DISPLAY_COUNT ? { height: '100%', width: '100%' } : { width: '100%' }
            }
            childrenContainerSx={{ margin: '0 8px 10px 20px', height: '100%' }}>
            {regionEntitlements.length > 0 ? !bcScreenerQuery?.isLoading && (bcCompanyList?.length || 0) > 0 && (
                <AgGirdCard
                    showDefaultExportButton={false}
                    ref={gridRef}
                    columnDefs={columnDefs}
                    suppressRowClickSelection={true}
                    customFlexibleColumns={customFlexibleColumns}
                    getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                    rowsData={bcCompanyList || []}
                    gridTheme={gridTheme}
                    maxNumberOfRowsToDisplay={betweenMdAndLg ? 12 : MAX_ROWS_DISPLAY_COUNT}
                    unlimitedCalculatedHeight
                    useDragScroll
                    onColumnMovedGetter={keepNoLeftPaddingOnMove}
                    autoSizePadding={defaultAutosizePadding}
                    tooltipShowDelay={defaultTooltipShowDelay}
                    autosizeColumnsConfig={{
                        skipHeader: false,
                        skipHasPinnedColumnsCheck: true,
                    }}
                    suppressHeaderMenuButton={false}
                />
            ) : 
            <Grid container>
                <Grid item container xs={12} sx={{
                    borderBottom: '1px solid #AAAAAA',
                    paddingBottom: '10px',
                    marginBottom: '10px',
                    marginRight: '10px'
                }}>
                    <Grid item xs={2.5}>
                        <span style={styles.headers}>Ticker</span>
                    </Grid>
                    <Grid item xs={6.5}>
                        <span style={styles.headers}>Company</span>
                    </Grid>
                    <Grid item xs={3} textAlign={'right'}>
                        <span style={styles.headers}>Date Added</span>
                    </Grid>
                </Grid>
                <Grid sx={{ position: 'relative', width: '100%' }}>
                    <TableSkeleton sx={{ position: 'absolute' }} columns={[{ flex: 2.5 }, { flex: 6.5 }, { flex: 2.8 }]} numberOfRows={10} />
                </Grid>
                <Grid sx={{ padding: '20px', backdropFilter: 'blur(5px)' }} flexDirection={'column'} rowSpacing={5}>
                    <Grid item xs={12} marginBottom={'10px'}>
                        <TypographyStyle6 sx={{ fontSize: '32px', lineHeight: '38px', fontWeight: 600 }}>Access our highest conviction ideas</TypographyStyle6>
                    </Grid>
                    <Grid item xs={12} marginBottom={'10px'}>
                        <TypographyStyle6 sx={{ lineHeight: '22px', fontWeight: 500 }}>With our Biggest Concerns List, you have access to:</TypographyStyle6>
                    </Grid>
                    <Grid item xs={12} display={'flex'} flexDirection={'row'} marginBottom={'10px'}>
                        <Grid item xs={2} display={'flex'} alignItems={'center'}>
                            <StyledAvatar src={checkRounded} sx={{ height: '32px', width: '32px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <TypographyStyle6 sx={{ fontFamily: fontFamilies.GraphikRegular, lineHeight: '22px' }}>Our actively curated portfolio of global companies representing our highest conviction ideas</TypographyStyle6>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} display={'flex'} flexDirection={'row'} marginBottom={'20px'}>
                        <Grid item xs={2} display={'flex'} alignItems={'center'}>
                            <StyledAvatar src={checkRounded} sx={{ height: '32px', width: '32px' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <TypographyStyle6 sx={{ fontFamily: fontFamilies.GraphikRegular, lineHeight: '22px' }}>Companies that show substantial risk of deteriorating earnings quality or fraud</TypographyStyle6>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <RoundedTextButton
                            sx={{
                                fontSize: '13px',
                                borderRadius: '10px',
                                padding: '6px 25px',
                                '&:hover': { cursor: 'cursor' },
                            }}
                            theme={roundedTextButtonThemeV3}
                            buttonText={'Learn more'}
                            onClickCallback={() => {}}
                        />
                    </Grid>
                </Grid>
            </Grid>}
        </BannerCard>
    );
}

const styles = {
    headers: {
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '13.5px',
        fontWeight: 500,
        lineHeight: '20px',
        color: '#5A5A5A'
    }
}
