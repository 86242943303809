import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGridReact } from 'ag-grid-react';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { useMemo, useCallback, useRef } from 'react';
import { Box } from '@mui/material';

export function ScoreHistory(props: any) {
    let { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (!props.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }
        const result = extractFromScreenerData({ screenerData: props.data, cardName: 'ScoreHistory' });
        return result;
    }, [props.data]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const gridRef = useRef<AgGridReact>(null);

    if (!props.entitled) {
        columnDefs = columnDefs?.filter((p) => p.field === props.dateField) || [];
        columnDefs.push({
            headerName: props.scoreLabel || 'Score',
            field: 'dummy_blurr',
            cellRenderer: () => 
            (<Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Box width={'20px'} height={'12px'} sx={{ backgroundColor: 'rgba(128, 128, 128, 0.15)', borderRadius: '2px' }}></Box>
            </Box>),
            type: 'rightAligned',
            sortable: false,
            ignoreOnExport: true,
            resizable: false,
            flex: 1
        });
    }

    return (
        <AgGirdCard
            showDefaultExportButton={false}
            ref={gridRef}
            defaultRowHeight={30}
            columnDefs={columnDefs}
            suppressRowClickSelection={true}
            customFlexibleColumns={customFlexibleColumns}
            getResizableMinWidthForColumn={getResizableMinWidthForColumn}
            rowsData={props.data.results.company || []}
            maxNumberOfRowsToDisplay={4}
            gridTheme={['cp-grid-theme', 'ag-row-no-border']}
            headerHeight={42}
        />
    );
}
