import { Button, ETFCard, ETFIconButton, ETFInfoButton, Typography } from '@cfra-nextgen-frontend/shared';
import { Grid, Stack } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { PopupVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Popup/PopupVariant1';
import { AddToWatchlistComponent } from '@cfra-nextgen-frontend/shared/src/components/Watchlist/AddToWatchlist';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import Box from '@mui/material/Box';
import { cashFlowScoresDescription, commonPopupProps, earningsScoresDescription } from 'components/scores/shared';
import { WatchListButtonV12Theme, WatchListIconOutline, WatchListIconSelected } from 'components/themes/theme';
import { useState } from 'react';
import { ComponentProps } from '../types/company';
import { ReactComponent as lockIconRounded } from '@cfra-nextgen-frontend/shared/src/assets/icons/lock-icon-rounded.svg';
import { getScoresEntitlements } from '../api/company';

const getStacks = (company_overview: any) => [
    { title: 'Concern \n Level', color: '#F66C61', value: company_overview.frs_company_report?.overall_concern_level },
    {
        title: 'CFRA \n Earnings Score',
        color: '#002B5A',
        value: company_overview.frs_earnings_score_drivers?.decile,
        popupDescription: earningsScoresDescription,
    },
    {
        title: 'CFRA \n Cash Flow',
        color: '#609EA0',
        value: company_overview.frs_cash_flow_score_drivers?.decile,
        popupDescription: cashFlowScoresDescription,
    },
];

const getFormattedValue = (value: number) => (value === null || value === undefined ? 'NA' : value.toString());

const getStackComponentHeader = (stack: any) => {
    const showPopupIcon = stack.popupDescription !== undefined;

    return (
        <Stack sx={{ width: '100%', height: '100%', flexDirection: 'row' }} key={stack.title}>
            <Box sx={{ display: 'flex', alignItems: 'end', height: '100%' }}>
                <Typography
                    variant='scoreKeyStyle'
                    sx={{
                        width: 'auto',
                    }}>
                    {stack.title}
                </Typography>
            </Box>
            {showPopupIcon && (
                <Box
                    sx={{
                        width: '25px',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        alignItems: 'center',
                        justifyContent: 'end',
                        display: 'flex',
                    }}>
                    <PopupVariant1
                        {...commonPopupProps}
                        description={stack.popupDescription}
                        getOpenModalButton={(handleOpen) => {
                            return <ETFInfoButton buttonFontSize={15} onClick={handleOpen} />;
                        }}
                    />
                </Box>
            )}
        </Stack>
    );
};

const getStackComponentValue = (stack: any, index: number, entitled?: boolean) => {
    return (
        <Stack sx={{ width: '100%' }} key={stack.title}>
            {entitled ? <Typography
                variant='scoreValueStyle'
                paddingTop={'5px'}
                sx={{
                    color: stack.color,
                    ...(index < 2 ? { borderRight: '1px solid #E0DBDB' } : {}),
                }}>
                {getFormattedValue(stack.value)}
            </Typography> : 
            <Box sx={{
                display: 'flex',
                height: '50px',
                alignItems: 'center',
                ...(index < 2 ? { borderRight: '1px solid #E0DBDB' } : {})
            }}>
                <ETFIconButton
                    Icon={lockIconRounded}
                    viewBoxSize={34}
                    buttonStyles={{ padding: '0px', cursor: 'default' }}
                    backgroundcolor='#fff'
                />
            </Box>}
        </Stack>
    );
};

const getCompanyTickerExchange = (company: any) =>
    company.security_trading.ticker_symbol + ' - ' + company.security_trading.exchange_lid.exchange_code;

export function CompanyOverview({ company, handleLinkClick, reportID, userEntitlements }: ComponentProps) {
    const [isWatchListSelected, setWatchListSelected] = useState(false);

    if (company.isLoading) return <ETFCard.ETFCard isLoading={true} />;

    if (company?.data?.results?.company === undefined || company?.data?.results?.company.length === 0)
        return <ETFCard.ETFEmptyCard cardLabel='Company'></ETFCard.ETFEmptyCard>;

    const company_overview = company.data?.results.company[0] as any;
    const company_security = company_overview.company_security;

    const hasConcerns: boolean = company_overview.frs_biggest_concerns_list?.is_biggest_concern;
    const stacks = getStacks(company_overview);

    const iconTheme = isWatchListSelected ? WatchListIconOutline : WatchListIconSelected;

    const { userEarningsScoresEntitlements, userCashFlowEntitlements } = getScoresEntitlements(userEntitlements);

    return (
        <ETFCard.ETFCard>
            <Grid container>
                <Grid item xs={12} sm={7.2} lg={8.4}>
                    <Stack spacing={0.4}>
                        <Box>
                            <AddToWatchlistComponent
                                watchListData={[
                                    {
                                        cfra_company_id: company_security.company.cfra_company_id,
                                        cfra_security_trading_id:
                                            company_security.security_trading.cfra_security_trading_id,
                                        composite_name: company_security.company.company_name,
                                        id:
                                            company_security.company.cfra_company_id ||
                                            company_security.security_trading.cfra_security_trading_id,
                                    },
                                ]}
                                savedItemType={UserSavedItemsLookupID.InstitutionalWatchlist}
                                buttonTheme={WatchListButtonV12Theme}
                                buttonText='Watchlist'
                                customIconTheme={iconTheme}
                                tooltipText='Add company to a watchlist'
                                isWatchListSelected={isWatchListSelected}
                                setWatchListSelected={setWatchListSelected}
                            />
                        </Box>
                        <Box>
                            <Typography variant='cardCaption'>
                                {getCompanyTickerExchange(company_overview['company_security'] as any)}
                            </Typography>
                            <Typography variant='captionSep'>{'  |  '}</Typography>
                            <Typography variant='cardCaption'>
                                {company_overview['company_security'].company.company_name}
                            </Typography>
                        </Box>

                        {hasConcerns && (
                            <Box>
                                <Button
                                    variant='concerns'
                                    onClick={() => {
                                        if (handleLinkClick) {
                                            handleLinkClick(reportID || '');
                                        }
                                    }}>
                                    biggest Concern
                                </Button>
                            </Box>
                        )}
                    </Stack>
                </Grid>
                <Grid item container xs={12} sm={4.8} lg={3.6} sx={{ marginTop: { xs: '20px' } }}>
                    <Grid
                        item
                        container
                        xs={12}
                        columnSpacing={{
                            md: 2.5,
                            xs: 1.25,
                        }}>
                        {stacks.map((stack, index) => (
                            <Grid item key={`getStackComponentHeader_${index}`} xs={4}>
                                {getStackComponentHeader(stack)}
                            </Grid>
                        ))}
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        columnSpacing={{
                            md: 2.5,
                            xs: 1.25,
                        }}>
                        {userEntitlements && stacks.map((stack, index) => (
                            <Grid item key={`getStackComponentValue_${index}`} xs={4}>
                                {getStackComponentValue(stack, index, index === 1 ? userEarningsScoresEntitlements.length > 0 : (index === 2 ? userCashFlowEntitlements.length > 0 : true))}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </ETFCard.ETFCard>
    );
}
